import React from 'react';
import type { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NodeIconFragment } from '@aurora/shared-generated/types/graphql-types';
import { SharedComponent } from '../../../enums';
import Icon from '../../common/Icon/Icon';
import { IconSize } from '../../common/Icon/enums';
import type { IconAndColor } from '../../common/Icon/IconProps';
import useTranslation from '../../useTranslation';
import { getIconAndColorForNode } from '../../../helpers/nodes/NodeIconHelper';
import { getConversationStyle } from '../../../helpers/nodes/NodeHelper';

interface Props {
  /**
   * The node type being used.
   */
  node: NodeIconFragment;
  /**
   * Size of the node icon.
   */
  size?: IconSize;
  /**
   * Class name to apply to the component.
   */
  className?: string;
  /**
   * If the icon has a surrounding frame wrap.
   */
  useFrame?: boolean;
}

/**
 * Displays the icon for a node
 *
 * @constructor
 *
 * @author Adam Ayres, Willi Hyde
 */
const NodeIcon: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  className,
  useFrame,
  size = IconSize.PX_30
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.NODE_ICON);

  if (textLoading) {
    return null;
  }

  const conversationStyle: ConversationStyle = getConversationStyle(node);
  const iconAndColor: IconAndColor = getIconAndColorForNode(node);

  if (!iconAndColor) {
    return null;
  }

  const { icon, color } = iconAndColor;

  return (
    <Icon
      icon={icon}
      size={size}
      className={className}
      useFrame={useFrame}
      color={color}
      ariaLabel={
        conversationStyle
          ? formatMessage('contentType', { style: conversationStyle.toString() })
          : ''
      }
    />
  );
};

export default NodeIcon;
