import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import MembershipTypeForNode from '@aurora/shared-client/components/memberships/MembershipTypeForNode/MembershipTypeForNode';
import NodeAvatar from '@aurora/shared-client/components/nodes/NodeAvatar/NodeAvatar';
import NodeDescription from '@aurora/shared-client/components/nodes/NodeDescription/NodeDescription';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import nodeQuery from '@aurora/shared-client/components/nodes/NodeView.query.graphql';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { getLog } from '@aurora/shared-utils/log';
import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { LastActivityTimePrependText, SubscriptionActionVariant } from '../../../types/enums';
import type {
  MembershipTypeForNodeFragment,
  NodeMembersCountFragment,
  NodeViewFragment,
  NodeViewQuery,
  NodeViewQueryVariables
} from '../../../types/graphql-types';
import type { ExtendedOverlayInjectedProps } from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import NodeLatestActivityTime from '../NodeLatestActivityTime/NodeLatestActivityTime';
import NodeLink from '../NodeLink/NodeLink';
import NodeMembersCount from '../NodeMembersCount/NodeMembersCount';
import NodeSubscriptionAction from '../NodeSubscriptionAction/NodeSubscriptionAction';
import NodeTopicsCount from '../NodeTopicsCount/NodeTopicsCount';

const log = getLog(module);

interface Props extends ExtendedOverlayInjectedProps {
  /**
   * The node to create the card for.
   */
  entity: NodeViewFragment;
}

/**
 * Displays Hover Card on hover of node link.
 *
 * @author Subhiksha Venkatesan
 */
const NodeHoverCard: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLElement>
> = forwardRef<HTMLDivElement, Props>(function NodeHoverCardForward(
  { entity: node, onMouseEnter, onMouseLeave, style },
  ref
) {
  const cx = useClassNameMapper();
  const {
    data: nodeQueryData,
    loading: nodeQueryLoading,
    error: nodeQueryError
  } = useQueryWithTracing<NodeViewQuery, NodeViewQueryVariables>(module, nodeQuery, {
    variables: {
      id: node.id,
      useNodeTopicsCount: true,
      useMembershipInformation: true,
      useMembershipType: true,
      useNodeDescription: true,
      useNodeLatestActivityTime: true,
      useNodeUserContext: true
    }
  });

  if (!nodeQueryData || nodeQueryLoading || nodeQueryError) {
    if (nodeQueryError) {
      log.error(
        `Error retrieving node data for hover card for node with id ${node.id}`,
        nodeQueryError
      );
    }

    return null;
  }

  const { coreNode } = nodeQueryData;
  const { description, topicsCount } = coreNode;
  const { membersCount } = coreNode as NodeMembersCountFragment;
  const { membershipType } = coreNode as MembershipTypeForNodeFragment;
  const showPostsCount = topicsCount > 0;
  const showMembersCount = membersCount > 0;
  const showMetadata = showPostsCount || showMembersCount;
  const showLatestActivityTime =
    coreNode?.messageActivity?.corePropertyChangeTime && coreNode.topicsCount > 0;

  /**
   * Renders the avatar of the node.
   */
  function renderNodeAvatar(): React.ReactElement {
    return (
      <NodeLink node={node} className={cx('lia-g-hovercard-icon')}>
        <NodeAvatar
          node={node}
          size={IconSize.PX_80}
          fallback={(): JSX.Element => <NodeIcon node={node} size={IconSize.PX_80} useFrame />}
        />
      </NodeLink>
    );
  }

  /**
   * Renders the title of the node.
   */
  function renderNodeTitle(): React.ReactElement {
    return (
      <NodeLink node={node} className={cx('lia-g-hovercard-link')}>
        <NodeTitle node={node} className={cx('lia-g-hovercard-title lia-g-clamp')} />
      </NodeLink>
    );
  }

  /**
   * Renders the latest activity time and membership type of the node.
   */
  function renderLatestActivityTimeAndMembershipType(): React.ReactElement {
    return (
      <div className={cx('lia-g-hovercard-meta-text lia-g-hovercard-divided-text')}>
        <NodeLatestActivityTime node={coreNode} textKey={LastActivityTimePrependText.UPDATED} />
        {showLatestActivityTime && membershipType && (
          <div className={cx('lia-g-divider-element')} />
        )}
        {membershipType && (
          <MembershipTypeForNode
            node={coreNode as MembershipTypeForNodeFragment}
            className={cx('text-uppercase')}
            useIcon={false}
            hideOpen={false}
          />
        )}
      </div>
    );
  }

  /**
   * Renders the description of the node.
   */
  function renderNodeDescription(): React.ReactElement {
    return (
      <NodeDescription
        node={coreNode}
        as="span"
        clampLines={3}
        className={cx('lia-g-hovercard-info')}
      />
    );
  }

  /**
   * Renders the post count of the node.
   */
  function renderNodePostCount(): React.ReactElement {
    return (
      <NodeTopicsCount
        useIcon={false}
        node={coreNode}
        useTable
        countAs="h6"
        className={cx('lia-g-hovercard-data-item')}
        countClassName={cx('lia-g-hovercard-data-number')}
        textClassName={cx('lia-g-hovercard-data-title')}
      />
    );
  }

  /**
   * Renders the members count of the node.
   */
  function renderNodeMembersCount(): React.ReactElement {
    return (
      <NodeMembersCount
        node={coreNode}
        useTable
        countAs="h6"
        className={cx('lia-g-hovercard-data-item')}
        countClassName={cx('lia-g-hovercard-data-number')}
        textClassName={cx('lia-g-hovercard-data-title')}
      />
    );
  }

  /**
   * Renders the body part of the hover card
   */
  function renderBody(): React.ReactElement {
    return (
      <>
        {renderNodeAvatar()}
        {renderNodeTitle()}
        {renderLatestActivityTimeAndMembershipType()}
        {description && renderNodeDescription()}
      </>
    );
  }

  /**
   * Renders the metaData part of the hover card.
   */
  function renderMetaData(): React.ReactElement {
    return (
      <div className={cx('lia-g-hovercard-data')}>
        {showMembersCount && renderNodeMembersCount()}
        {showPostsCount && renderNodePostCount()}
      </div>
    );
  }

  /**
   * Renders the subscription action for subscription/memberships.
   */
  function renderNodeSubscriptionAction(): React.ReactElement {
    return (
      <NodeSubscriptionAction
        node={coreNode}
        variant={SubscriptionActionVariant.BUTTON}
        className={cx('lia-g-hovercard-btn')}
        useSuccessFeedback={false}
        useHoverEffect={true}
      />
    );
  }

  return (
    <div
      className={cx('lia-g-hovercard')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
      style={style}
    >
      {renderBody()}
      {showMetadata && renderMetaData()}
      {renderNodeSubscriptionAction()}
    </div>
  );
});

export default NodeHoverCard;
