import useDateTime from '@aurora/shared-client/components/useDateTime';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { LastActivityTimePrependText } from '../../../types/enums';
import type { NodeLatestActivityTimeFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The node to display the description for.
   */
  node: NodeLatestActivityTimeFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The text to prepend the date.
   */
  textKey?: LastActivityTimePrependText;
  /**
   * Whether to use a text when there is no activity yet or not.
   */
  useNoActivityText?: boolean;
}

/**
 * Displays a time stamp for the last activity in a node, with optional
 * display of icon.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const NodeLatestActivityTime: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'span',
  className,
  textKey = LastActivityTimePrependText.LATEST_ACTIVITY,
  useNoActivityText = false
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_LATEST_ACTIVITY_TIME
  );
  const { getUserPreferredDateFormat } = useDateTime();
  const cx = useClassNameMapper();

  if (textLoading) {
    return null;
  }

  const latestActivityTime = node?.messageActivity?.corePropertyChangeTime;

  const showLatestActivityTime = latestActivityTime && node.topicsCount > 0;

  return showLatestActivityTime ? (
    <Component className={cx(className)} suppressHydrationWarning={true}>
      {textKey === LastActivityTimePrependText.NONE
        ? getUserPreferredDateFormat(latestActivityTime)
        : formatMessage(textKey === LastActivityTimePrependText.UPDATED ? 'updatedAt' : 'title', {
            latestActivityTime: getUserPreferredDateFormat(latestActivityTime)
          })}
    </Component>
  ) : useNoActivityText ? (
    <> {formatMessage('noActivity')} </>
  ) : null;
};

export default NodeLatestActivityTime;
