import type { IconColor } from '@aurora/shared-client/components/common/Icon/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { NodeMembersCountFragment, NodeViewFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './NodeMembersCount.module.pcss';

interface Props {
  /**
   * The node for which members count is displayed for.
   */
  node: NodeViewFragment | NodeMembersCountFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the icon element.
   */
  iconClassName?: string;

  /** Color to apply to the icon element. */
  iconColor?: IconColor;

  /** Size to apply to the icon element */
  iconSize?: IconSize;

  /**
   * Include an icon in the component element.
   */
  useIcon?: boolean;
  /**
   * Include text in the component element.
   */
  useText?: boolean;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for members count component.
   */
  countAs?: React.ElementType;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Displays the node members count with optional icon next to the count.
 *
 * @constructor
 *
 * @author Shalin Amin
 */
const NodeMembersCount: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'span',
  className,
  useText = true,
  useIcon = false,
  iconClassName,
  iconColor,
  iconSize = IconSize.PX_12,
  countAs: ComponentAs = 'span',
  useTable = false,
  textClassName,
  countClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.NODE_MEMBERS_COUNT);

  if (textLoading) {
    return null;
  }

  function isNodeMembership(
    nodeMembersCnt: NodeMembersCountFragment | NodeViewFragment
  ): nodeMembersCnt is NodeMembersCountFragment {
    return (nodeMembersCnt as NodeMembersCountFragment).membersCount !== undefined;
  }
  const { membersCount } = isNodeMembership(node) ? node : { membersCount: 0 };

  return (
    <Component className={cx(className, 'lia-node-member-count')} data-testid="NodeMembersCount">
      {useTable ? (
        <FormattedMessage
          id="title"
          values={{
            count: membersCount,
            span: function renderChunks(chunks): React.ReactNode {
              return <span className={cx(textClassName)}>&nbsp;{chunks}</span>;
            },
            membersCountRender: function renderCount(): React.ReactNode {
              return <ComponentAs className={cx(countClassName)}>{membersCount}</ComponentAs>;
            }
          }}
        />
      ) : (
        <>
          {useIcon && (
            <Icon
              icon={Icons.UserIcon}
              color={iconColor}
              size={iconSize}
              className={cx(iconClassName, 'lia-node-member-count-icon')}
            />
          )}
          {useText ? (
            <span>{formatMessage('membersCountForText', { count: membersCount })}</span>
          ) : (
            <span>{membersCount}</span>
          )}
        </>
      )}
    </Component>
  );
};

export default NodeMembersCount;
