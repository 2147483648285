import type { NodeAvatarFragment } from '@aurora/shared-generated/types/graphql-types';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import { mediaDimensionsFromIconSize } from '../../../helpers/images/MediaHelper';
import { IconSize } from '../../common/Icon/enums';
import useCrossOriginAttribute from '../../useCrossOriginAttribute';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The node to display the description for.
   */
  node: NodeAvatarFragment;
  /**
   * The size of the avatar to display.
   */
  size?: IconSize;
  /**
   * The fallback component to render in the instance that the node has no associated image.
   */
  fallback?: React.ComponentType<React.PropsWithChildren<unknown>>;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
}

/**
 * Displays the avatar for a node.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const NodeAvatar: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  node,
  size = IconSize.PX_50,
  fallback: FallbackComponent = null,
  as: Component = 'img'
}) => {
  const cx = useClassNameMapper();
  const crossOrigin = useCrossOriginAttribute();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.NODE_AVATAR);
  if (textLoading) {
    return null;
  }

  let imageUrl = null;
  const originalHref = node?.avatar?.url;

  if (originalHref) {
    const dimensions = mediaDimensionsFromIconSize(size);
    imageUrl = UrlHelper.adjustImageServerPageUrl(originalHref, dimensions);
  } else {
    return <FallbackComponent />;
  }

  if (imageUrl === null) {
    if (FallbackComponent !== null) {
      return <FallbackComponent />;
    }
    return null;
  }

  return (
    <Component
      src={imageUrl}
      alt={formatMessage('altTitle', { nodeTitle: node.title })}
      className={cx(`lia-g-icon-size-${size}`, className)}
      data-testid="NodeAvatar"
      crossOrigin={crossOrigin(imageUrl)}
    />
  );
};

export default NodeAvatar;
