import type { NodeIconFragment } from '@aurora/shared-generated/types/graphql-types';
import type { IconAndColor } from '../../components/common/Icon/IconProps';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import IconTypes from '../../public/static/graphics/processed/enums';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { IconColor } from '../../components/common/Icon/enums';
import { getLog } from '@aurora/shared-utils/log';
import { getConversationStyle } from './NodeHelper';

const log = getLog(module);

export const nodeIcons: Record<NodeType, Required<IconAndColor>> = {
  [NodeType.CATEGORY]: { icon: IconTypes.ContentCategoryIcon, color: IconColor.CATEGORY },
  [NodeType.GROUPHUB]: { icon: IconTypes.ContentGroupIcon, color: IconColor.GROUPHUB },
  [NodeType.COMMUNITY]: { icon: IconTypes.HomeIcon, color: IconColor.COMMUNITY },
  [NodeType.BOARD]: null
};

export const boardIcons: Record<ConversationStyle, Required<IconAndColor>> = {
  [ConversationStyle.Forum]: { icon: IconTypes.ContentDiscussionIcon, color: IconColor.FORUM },
  [ConversationStyle.Blog]: { icon: IconTypes.ContentBlogIcon, color: IconColor.BLOG },
  [ConversationStyle.Tkb]: { icon: IconTypes.ContentKnowledgeIcon, color: IconColor.KNOWLEDGE },
  [ConversationStyle.Idea]: { icon: IconTypes.ContentIdeaIcon, color: IconColor.IDEA },
  [ConversationStyle.Qanda]: {
    icon: IconTypes.ContentDiscussionQuestionIcon,
    color: IconColor.QUESTION
  },
  [ConversationStyle.Contest]: { icon: IconTypes.ContentContestIcon, color: IconColor.CONTEST },
  [ConversationStyle.Occasion]: { icon: IconTypes.ContentOccasionIcon, color: IconColor.OCCASION },
  [ConversationStyle.Group]: { icon: IconTypes.ContentGroupIcon, color: IconColor.GROUP }
};

/**
 * Given a node, attempts to return an {@link IconAndColor} based on hardcoded information
 * Failing that, returns `null`
 */
export function getIconAndColorForNode(node: NodeIconFragment): IconAndColor | null {
  let iconAndColor: IconAndColor;
  const conversationStyle: ConversationStyle = getConversationStyle(node);
  const { nodeType } = node;
  if (nodeType) {
    if (nodeType === NodeType.BOARD) {
      iconAndColor = boardIcons[conversationStyle];
    } else {
      iconAndColor = nodeIcons[nodeType];
    }
  }

  if (!iconAndColor) {
    log.warn('No icon is registered in NodeIcon for the specified node: %s', node);
    return null;
  }

  return iconAndColor;
}
