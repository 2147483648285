import { MembershipType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { MembershipTypeForNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import Icons from '../../../icons';
import { IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import useTranslation from '../../useTranslation';
import localStyles from './MembershipTypeForNode.module.css';

/**
 * MembershipTypeForNode Props.
 */
export interface MembershipTypeForNodeProps {
  /**
   * The node for which members count is displayed for.
   */
  node: MembershipTypeForNodeFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Include a lock icon beside the component for.
   */
  useIcon: boolean;
  /**
   * Do not show the lock icon when the membershipType is Open
   */
  hideOpen?: boolean;
  /**
   * Include node type in the component element.
   */
  useNodeType?: boolean;
}

/**
 * Displays a badge or custom component showing the membership type for a given node.
 *
 * @constructor
 *
 * @author Jonathan Bridges
 */
const MembershipTypeForNode: React.FC<React.PropsWithChildren<MembershipTypeForNodeProps>> = ({
  node,
  as: Component = 'div',
  className,
  useIcon,
  hideOpen = true,
  useNodeType = false
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.MEMBERSHIP_TYPE_FOR_NODE
  );
  const { membershipType } = node;
  const membershipTypeText = useNodeType
    ? `membershipType.${membershipType?.toLowerCase()}.${node.nodeType}`
    : `membershipType.${membershipType?.toLowerCase()}`;

  if (textLoading || (hideOpen && membershipType === MembershipType.Open)) {
    return null;
  }

  return (
    <Component className={cx(className)}>
      {useIcon && membershipType !== MembershipType.Open ? (
        <Icon
          icon={Icons.LockIcon}
          size={IconSize.PX_12}
          className={cx('lia-g-mr-5')}
          testId="MembershipTypeForNode.LockIcon"
        />
      ) : null}
      <span>{formatMessage(membershipTypeText)}</span>
    </Component>
  );
};

export default MembershipTypeForNode;
