import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import Icons from '@aurora/shared-client/icons';
import type { BoardPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { MembershipType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NodeIsMembershipFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getBoardViewAllPageAndQueryPathParams } from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type {
  NodeMembershipTypeFragment,
  NodeTopicsCountFragment,
  NodeViewFragment
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './NodeTopicsCount.module.css';

interface Props {
  /**
   * The node to display the topics count for.
   */
  node:
    | NodeViewFragment
    | (NodeTopicsCountFragment & NodeIsMembershipFragment & NodeMembershipTypeFragment);
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Whether the icon is displayed next to the topics count.
   */
  useIcon?: boolean;
  /**
   * Whether a neutral label is displayed along with the topic count or not
   */
  useNeutralLabel?: boolean;
  /**
   * Class name(s) to apply to the icon element.
   */
  iconClassName?: string;

  /** Color to apply to the icon element  */
  iconColor?: IconColor;

  /** Size for the icon element  */
  iconSize?: IconSize;

  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for topics count component.
   */
  countAs?: React.ElementType;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

function isNodeMembershipType(
  node: NodeViewFragment | NodeMembershipTypeFragment
): node is NodeMembershipTypeFragment {
  return (node as NodeMembershipTypeFragment).membershipType !== undefined;
}

function getMembershipType(
  node:
    | NodeViewFragment
    | (NodeTopicsCountFragment & NodeIsMembershipFragment & NodeMembershipTypeFragment)
): NodeMembershipTypeFragment {
  if (isNodeMembershipType(node)) {
    return node as NodeMembershipTypeFragment;
  }
  return { membershipType: null };
}

function isNodeMember(
  node: NodeViewFragment | NodeIsMembershipFragment
): node is NodeIsMembershipFragment {
  return (node as NodeIsMembershipFragment).isMember !== undefined;
}

function getIsMember(
  node:
    | NodeViewFragment
    | (NodeTopicsCountFragment & NodeIsMembershipFragment & NodeMembershipTypeFragment)
): NodeIsMembershipFragment {
  if (isNodeMember(node)) {
    return node as NodeIsMembershipFragment;
  }
  return { isMember: null };
}

/**
 * Displays the node topics count with optional icon next to the count.
 *
 * @author Adam Ayres
 */
const NodeTopicsCount: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'span',
  className,
  useIcon = true,
  useNeutralLabel = false,
  countAs: ComponentAs = 'span',
  iconClassName,
  iconColor = IconColor.GRAY_600,
  iconSize = IconSize.PX_16,
  useTable = false,
  textClassName,
  countClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.NODE_TOPICS_COUNT);
  const { topicsCount } = node;
  const { membershipType } = getMembershipType(node);
  const { isMember } = getIsMember(node);
  const { contextNode } = useContext(AppContext);
  const { Link, router } = useEndUserRoutes();
  const currentPage = router.getCurrentPageName();
  const isPostCountClickable =
    currentPage === EndUserPages.BlogBoardPage ||
    currentPage === EndUserPages.TkbBoardPage ||
    currentPage === EndUserPages.ForumBoardPage ||
    currentPage === EndUserPages.IdeaBoardPage ||
    currentPage === EndUserPages.EventBoardPage;

  if (textLoading) {
    return null;
  }

  if (membershipType === MembershipType.Closed && !isMember) {
    return null;
  }

  /***
   * Function to render the topics count.
   */
  function renderTopicsCount(): React.ReactElement {
    if (isPostCountClickable) {
      const { page, pathParams } = getBoardViewAllPageAndQueryPathParams(contextNode);
      return (
        <Link<BoardPagesAndParams> route={page} params={pathParams} passHref legacyBehavior={true}>
          <Button
            variant={ButtonVariant.UNSTYLED}
            as="a"
            className={cx({ 'lia-topics-count-text': useIcon === true })}
            data-testid="NodeTopicsCount.PostCountBtn"
          >
            {formatMessage('topicCount', { count: topicsCount })}
          </Button>
        </Link>
      );
    } else {
      return (
        <span className={cx({ 'lia-topics-count-text': useIcon === true })}>
          {formatMessage('topicCount', { count: topicsCount })}
        </span>
      );
    }
  }

  return (
    typeof topicsCount === 'number' && (
      <Component data-testid="NodeTopicsCount" className={cx(className, 'lia-node-topics-count')}>
        {useTable ? (
          <FormattedMessage
            id="title"
            values={{
              count: topicsCount,
              span: function renderChunks(chunks): React.ReactNode {
                return <span className={cx(textClassName)}>&nbsp;{chunks}</span>;
              },
              topicsCountRender: function renderCount(): React.ReactNode {
                return <ComponentAs className={cx(countClassName)}>{topicsCount}</ComponentAs>;
              }
            }}
          />
        ) : (
          <>
            {useIcon ? (
              <Icon
                icon={Icons.CommentIcon}
                className={cx(iconClassName, 'lia-g-mr-5')}
                color={iconColor}
                size={iconSize}
              />
            ) : null}
            {useNeutralLabel ? (
              <>{formatMessage('topicCountWithNeutralLabel', { count: topicsCount })}</>
            ) : (
              renderTopicsCount()
            )}
          </>
        )}
      </Component>
    )
  );
};

export default NodeTopicsCount;
