import type NodeLinkProps from '@aurora/shared-client/components/nodes/NodeLink/NodeLinkProps';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type { NodePageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getNodeRouteAndParams } from '../../../helpers/nodes/NodeHelper/NodeHelper';
import type { NodeViewFragment } from '../../../types/graphql-types';
import type {
  ExtendedOverlayInjectedProps,
  ExtendedOverlayTriggerInjectedProps
} from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import ExtendedOverlayTrigger from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import useTranslation from '../../useTranslation';
import NodeHoverCard from '../NodeHoverCard/NodeHoverCard';

/**
 * Creates a NextJS link for a node.
 *
 * @author Dolan Halbrook, Adam Ayres
 */
const NodeLink: React.FC<React.PropsWithChildren<NodeLinkProps>> = ({
  node,
  children,
  className,
  as: Component = 'a',
  openInANewTab = false,
  useHoverCard = false,
  ariaLabel
}) => {
  const cx = useClassNameMapper();
  const { Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { route, params } = getNodeRouteAndParams(node);
  const targetValue = openInANewTab ? '_blank' : '_self';

  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.NODE_LINK);

  if (textLoading) {
    return null;
  }

  if (!route) {
    return <>{children}</>;
  }

  const ariaLabelText = ariaLabel ?? (node?.title && formatMessage('place', { name: node.title }));
  const ariaLabelProp = {};
  if (ariaLabelText) {
    ariaLabelProp['aria-label'] = ariaLabelText;
  }

  function renderLink(triggerProps: ExtendedOverlayTriggerInjectedProps = null) {
    const finalParams =
      params &&
      Object.fromEntries(Object.keys(params).map(key => [key, getCaseSensitivePath(params[key])]));
    return (
      <Link<NodePageAndParams> route={route} params={finalParams} legacyBehavior={true}>
        <Component
          /* eslint-disable react/jsx-props-no-spreading */
          {...triggerProps}
          data-testid="nodeLink"
          target={targetValue}
          className={cx(className)}
          {...ariaLabelProp}
        >
          {children}
        </Component>
      </Link>
    );
  }

  function renderPopover({ onMouseEnter, onMouseLeave, ref, style }: ExtendedOverlayInjectedProps) {
    return (
      <NodeHoverCard
        ref={ref}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        entity={node as NodeViewFragment}
      />
    );
  }

  if (route) {
    if (useHoverCard) {
      return <ExtendedOverlayTrigger overlay={renderPopover}>{renderLink}</ExtendedOverlayTrigger>;
    }
    return renderLink();
  }

  return (
    <span className={cx(className)} data-testid="nodeLink">
      <span className={cx('sr-only h-auto')}>
        {ariaLabel && formatMessage('place', { name: ariaLabel })}
      </span>
      <span aria-hidden="true">{children}</span>
    </span>
  );
};

export default NodeLink;
